import React, { Component, useContext, useRef, useEffect, useState } from "react"
import { Tooltip as MateriaUiTooltip, makeStyles, MenuItem, Select, Radio } from "@material-ui/core"
import { Modal, ModalHeader, ModalBody, ModalFooter, Label, FormText, FormGroup, Input } from "reactstrap"
import {
  Button,
  Form,
} from "reactstrap"

import { AccountsContext } from "./context"
import FilterControls from "./filtercontrols"

import OutBoundDataTransfer_widget from "./controls/outbound-data-transfer"
import PreviousCompare_widget from "./controls/previous-compare"
import { css } from "glamor"
import { fetch_get, fetch_post } from "../../../utils/fetch"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import Chart from "react-apexcharts"


function AccountsIndex(props) {
  let contextData = useContext(AccountsContext)

  const useStyles = makeStyles(() => ({
    modal: {
      width: 1000,
    },
    form: {
      maxHeight: "calc(100vh - 200px)",
      overflowY: "auto",
      margin: "0 3rem 1rem 3rem",
    },
  }))
  const [modal, setModal] = useState(false)
  const [info, setInfo] = useState({
    scope: "All",
    depth: " ",
    depthType: "All",
    org_id: "All",
  })
  const [infoTwo, setInfoTwo] = useState({
    usage: 0,
    eMailStatus: false,
    subscribers: "",

  })
  const { getAccessTokenSilently } = useAuth0()
  const [ipsData, setIpData] = useState([])
  const [processAdvice, setProcessAdvice] = useState([])
  const [processMax, setProcessMax] = useState("")
  const [processValue, setProcessValue] = useState("")
  const [orgNewList, setOrgNewList] = useState([])
  const [scale, setScale] = useState("")
  var [loadStatus, setLoadStatus] = useState({ loading: false, error: false, errorMessage: null })

  const toggle = () => {
    if (modal) {
      setInfo({
        scope: "All",
        depth: "",
        depthType: "All",
        org_id: "All",
      })
      setInfoTwo({
        usage: 0,
        eMailStatus: false,
        subscribers: "",

      })
    }
    setModal(!modal)
  }
  const classes = useStyles()

  useEffect(() => {
    if (modal) {
      getIP()
      drawBar()
      get_organizations_promise()
    }
  }, [modal])
  useEffect(() => {
    drawBar()
  }, [processValue, processMax])
  useEffect(() => {
    onCheck()
  }, [info])
  var [chartSettings, setChartSettings] = useState({})
  const drawBar = () => {
    var cs = {
      series: [{
        name: "IP:" + info.depth,
        data: [Number(processValue)],
      }, {
        name: "remainder",
        data: [Number(processMax) - Number(processValue)],
      }],
      options: {
        chart: {
          type: "bar",
          height: 150,
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 10,
          },
        },

        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "",
          show: false,
        },
        xaxis: {
          categories: [""],
          show: false,
          labels: {
            show: false,
            formatter: function(val) {
              return val
            },
          },
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val + scale
            },
          },
        },
        fill: {
          opacity: 1,

        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
    }

    setChartSettings(cs)
  }
  const onSubmit = () => {
    setLoadStatus({ loading: true, error: false, errorMessage: null })
    let obj = Object.assign(info, infoTwo)
    if (obj.depthType == "All") {
      obj.depth = "All"
    }
    if (obj.depth == " ") {
      alert("Please select an IP")
      setLoadStatus({ loading: false, error: false, errorMessage: null })
    } else {
      if (obj.org_id==''||obj.org_id=='All'){
        alert("Please select a Organization")
      }else{
        delete obj.depthType
        delete obj.eMailStatus
        getAccessTokenSilently()
          .then(accessToken =>
            fetch_post(`admin/data-transfer-threshold/parent-organization`, obj, accessToken)
              .then(response => {
                toggle()
                setLoadStatus({ loading: false, error: false, errorMessage: null })
              })
              .catch(error => {
                Sentry.captureException(error)
                setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
                console.log(`Error while getting workgroup links list: ${error}`)
              }),
          )
          .catch(error => {
            Sentry.captureException(error)
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            console.log(`Error while getting acdcess token: ${error}`)
          })
      }

    }
  }
  const onCheck = async (event) => {
    setLoadStatus({ loading: true, error: false, errorMessage: null })
    const sentinfo = info
    if (sentinfo.depthType == "All") {
      sentinfo.depth = "All"
    }
    await getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(`admin/data-transfer-threshold/parent-organization?scope=${sentinfo.scope}&depth=${sentinfo.depth}&org_id=${info.org_id}`, accessToken)
          .then(response => {
            if (response && response.user_data_bytes) {
              setProcessValue(response.user_data_bytes)
            }
            if (response && response.total_data_bytes) {
              setProcessMax(response.total_data_bytes)
            }
            if (response && response.threshold.length > 0) {
              setProcessAdvice(response.threshold[0].q3)
            }
            if (response && response.scale) {
              setScale(response.scale)
            }
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            console.log(`Error while getting workgroup links list: ${error}`)
            return
          }),
      )
      .catch(error => {
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        console.log(`Error while getting acdcess token: ${error}`)
      })
    await getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(`admin/data-transfer-threshold/usage?scope=${sentinfo.scope}&depth=${sentinfo.depth}&visibility=Parent Organization&org_id=${info.org_id}`, accessToken)
          .then(response => {
            setInfoTwo({ ...infoTwo, usage: response.usage })
          })
          .catch(error => {
            console.log(`Error while getting workgroup links list: ${error}`)
            return
          }),
      )
      .catch(error => {
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }
  // get IPs
  const getIP = async () => {
    setLoadStatus({ loading: true, error: false, errorMessage: null })
    await getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(`admin/data-transfer-threshold/all-ips`, accessToken)
          .then(response => {
            if (response.length > 0) {
              setIpData(response)

            }
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            console.log(`Error while getting workgroup links list: ${error}`)
            return
          }),
      )
      .catch(error => {
        Sentry.captureException(error)
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }
  const ipData = () => {
    return (
      <>
        {
          ipsData.length > 0 && ipsData.map(ele => {
            return <option key={ele}>{ele}</option>
          })
        }
      </>
    )
  }
  const infoChange = e => {
    setInfo({ ...info, [e.name]: e.value })
  }
  const infoTwoChange = e => {
    setInfoTwo({ ...infoTwo, [e.name]: e.value })
  }
  let url = "ui/organizations?includeorgid=1&includechildorgid=1&ui=1"
  const get_organizations_promise = () => {
    getAccessTokenSilently()
      .then(accessToken => fetch_get(url, accessToken))
      .then(response => {
        const parListArr = Object.values(response)
        setOrgNewList(parListArr)
      })
  }
  const fileModalRender = () => {
    return (
      <>
        <Chart
          options={chartSettings.options}
          series={chartSettings.series}
          type="bar"
          width="100%"
          height="200px"
        />

        <Form method="POST" className={classes.form}>
          <Label for="scope">
            Select the scope
          </Label>
          <FormGroup>
            <Input
              id="scope"
              name="scope"
              type="select"
              value={info.scope}
              onChange={event => infoChange(event.target)}
            >
              <option>Git</option>
              <option>Artifactory</option>
              <option>Bundles</option>
              <option>All</option>
            </Input>
          </FormGroup>
          <Label for="depthType">
            Select all IPs or single IP
          </Label>
          <FormGroup>
            <Input
              id="depthType"
              name="depthType"
              type="select"
              value={info.depthType}
              onChange={event => infoChange(event.target)}
            >
              <option>Single IP</option>
              <option>All</option>
            </Input>
          </FormGroup>
          {info.depthType !== "All" && <>
            <Label for="depth">
              Select IP
            </Label>
            <FormGroup>
              <Input
                id="depth"
                name="depth"
                type="select"
                value={info.depth}
                onChange={event => infoChange(event.target)}
              >
                <option></option>
                {ipData()}
              </Input>
            </FormGroup>
          </>}
          <FormGroup>
            <Label for="org_id">Organization</Label>
            <Input
              type="select"
              name="org_id"
              id="org_id"
              value={info.org_id}
              onChange={event => infoChange(event.target)}
            >
              <option></option>
              {orgNewList.map((p, index) => (
                <option value={p.orgid} key={p.orgid}>
                  {p["org_key"]}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Form>
        {/*<Form className={classes.form}>*/}
        {/*  <FormGroup>*/}
        {/*    <Button onClick={onCheck}>*/}
        {/*      Check*/}
        {/*    </Button>*/}
        {/*  </FormGroup>*/}
        {/*</Form>*/}

        <Form className={classes.form}>
          <FormGroup>
            <div style={{ width: 400 }}>
              <span for="usage" style={{ marginRight: 10 }}>
                Trigger@
              </span>
              <Input
                id="usage"
                name="usage"
                type="number"
                value={infoTwo.usage}
                onChange={event => infoTwoChange(event.target)}
                style={{ display: "inline-block", width: 100, marginRight: 10 }}
              />
              <span style={{ marginRight: 10 }}>GB</span>
              <span style={{ color: "#dee2e6", fontSize: "14px" }}>Recommended value:{processAdvice}</span>
            </div>

          </FormGroup>

          <FormGroup check>
            <Input type="checkbox"
                   id="eMailStatus"
                   name="eMailStatus"
                   value={infoTwo.eMailStatus}
                   onChange={event => infoTwoChange({ name: "eMailStatus", value: !infoTwo.eMailStatus })} />
            {" "}
            <Label check>
              Separate multiple email with commas
            </Label>
          </FormGroup>
          {infoTwo.eMailStatus && <FormGroup>
            <Label for="exampleEmail">
              Email
            </Label>
            <Input
              id="subscribers"
              name="subscribers"
              value={infoTwo.subscribers}
              onChange={event => infoTwoChange(event.target)}
              type="email"
            />
          </FormGroup>}
          <Button onClick={onSubmit}>
            Save
          </Button>
        </Form>
      </>
    )
  }
  return (
    <React.Fragment>
      <FilterControls toggle={toggle}/>
      {/*<hr />*/}
      {contextData.hasRecords && (
        <>
          {/* <div className="float-right">
            <button
              type="button"
              className="btn btn-outline-danger btn-sm"
              onClick={() => exportToPdf()}
            >
              <FaRegFilePdf />
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={() => exportToExcel()}
            >
              <FaRegFileExcel />
            </button>
          </div>

          <div>&nbsp;</div>
          <div>&nbsp;</div> */}
        </>
      )}

      <div id="AccountReportContent" style={{ marginBottom: 10 }}>
        {contextData.hasRecords && (
          <>
            {/* <div id="accounts-report-header">
              {!(
                _.isNil(contextData.selectedParentOrganization) ||
                _.isNil(contextData.selectedParentOrganization.id)
              ) && (
                <>
                  <strong>Organization : </strong>
                  {contextData.selectedParentOrganization.name}
                </>
              )}
              {!(
                _.isNil(contextData.selectedOrganization) ||
                _.isNil(contextData.selectedOrganization.id)
              ) && (
                <>
                  &nbsp;(
                  {contextData.selectedOrganization.name})
                </>
              )}
              <br />
              <strong>Period : </strong>
              {moment(contextData.startDate).format("YYYY-MM-DD")}
              &nbsp;&nbsp;<strong>To:</strong>&nbsp;
              {moment(contextData.endDate).format("YYYY-MM-DD")}
              <hr />
            </div> */}
          </>
        )}
        <OutBoundDataTransfer_widget />
        <br />
        <PreviousCompare_widget />
      </div>
      {/*<Button color="danger" onClick={toggle}>*/}
      {/*  Set Threshold Notifications*/}
      {/*</Button>*/}
      <Modal isOpen={modal} toggle={toggle} style={{ width: "1000px", maxWidth: 1000 }}{...props}>
        <ModalHeader toggle={toggle}>Set Threshold by Data Usage</ModalHeader>
        <ModalBody>
          {loadStatus.loading && !loadStatus.error && (
            <>
              <div className="spinner-grow spinner-grow-sm" role="status"></div>
              Loading...
            </>
          )}
          {!loadStatus.loading && !loadStatus.error && (
            fileModalRender()
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default AccountsIndex
