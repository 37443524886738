import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { AccountsContext } from "../context"
import {
  fetch_delete,
  fetch_get,
  fetch_patch,
  fetch_post,
  fetch_put,
} from "../../../../utils/fetch"
// import { sleep } from "../../../utils/common"
import * as Sentry from "@sentry/gatsby"
import _ from "lodash"
import { FaRedoAlt, FaRegFileExcel } from "react-icons/fa"

function TotalProjects(props) {
  let contextData = useContext(AccountsContext)
  const { getAccessTokenSilently } = useAuth0()
  var [show, setShow] = useState(false)
  var [dbRecords, setdbRecords] = useState([])
  var [loadStatus, setLoadStatus] = useState({ loading: false, error: false, errorMessage: null })

  const LoadTotalUsersReport = async () => {
    var startDate = contextData.startDate
    var endDate = contextData.endDate
    var postdata = { startDate: startDate, endDate: endDate }
    setLoadStatus({ loading: true, error: false, errorMessage: null })
    // await sleep(500)
    getAccessTokenSilently()
      .then(accessToken =>
        // fetch_get(`admin/${orgCode}/total/projects/report`, accessToken)
        fetch_post(`admin/data-transfer-management/summary-info`, postdata, accessToken)
          .then(response => {
            if (response.length > 0) contextData.setHasRecords(true)
            setdbRecords(response)
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            Sentry.captureException(error)
            console.log(`Error while getting workgroup links list: ${error}`)
          })
      )
      .catch(error => {
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        Sentry.captureException(error)
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }

  useEffect(() => {
    // console.log("from load users", contextData.selectedParentOrganization)
    if (contextData.submitCounter === 0) return

    setShow(true)
    LoadTotalUsersReport()
  }, [contextData.submitCounter])

  return (
    <React.Fragment>
      <>
        {show && (
          <>
            <div id="accounts-projects-data">
              {!contextData.pdfExportStarted && 1 == 2 && (
                <>
                  <div className="float-right">
                    <button
                      type="button"
                      className="btn btn-outline-dark btn-sm"
                      onClick={() => LoadTotalUsersReport()}
                    >
                      {/* <strong>Refresh</strong> */}
                      <FaRedoAlt />
                    </button>
                    {/* &nbsp;
                    <button
                      type="button"
                      className="btn btn-warning btn-sm"
                      onClick={() =>
                        window["generatePDF"](
                          "accounts-projects-data",
                          "AccountBilling-total-projects"
                        )
                      }
                    >
                      <strong>pdf</strong>
                    </button> */}
                    &nbsp;
                    <button
                      type="button"
                      className="btn btn-outline-success btn-sm"
                      onClick={() =>
                        window["exportTableToExcel"](
                          "accounts-excel-projects-data",
                          "",
                          "AccountBilling-total-projects-excel"
                        )
                      }
                    >
                      {/* <strong>excel</strong> */}
                      <FaRegFileExcel />
                    </button>
                  </div>
                  {/* <br />
                  <br /> */}
                </>
              )}

              <div>
                {loadStatus.loading && !loadStatus.error && (
                  <>
                    <div className="spinner-grow spinner-grow-sm" role="status"></div> Loading...
                  </>
                )}
                {!loadStatus.loading && !loadStatus.error && (
                  <>
                    <div class="list-group">
                      <li
                        class="list-group-item list-group-item-action bg-dark text-white border border-dark rounded"
                        aria-current="true"
                      >
                        <div class="d-flex w-100 justify-content-between">
                          <h5 class="mb-1">Data Transfer Cost MTD</h5>
                          <small class="badge" style={{ fontSize: "20px" }}>
                            {dbRecords.data.curr_bytes_cost}
                          </small>
                        </div>
                        {/* <p class="mb-1">Some placeholder content in a paragraph.</p> */}
                        <small>{dbRecords.data.cur_prev_percentage_increase}</small>
                      </li>
                      <br />
                      <li class="list-group-item list-group-item-action border border-dark rounded">
                        <div class="d-flex w-100 justify-content-between">
                          <h5 class="mb-1">Average Transfer By Visitor IP</h5>
                          <small class="badge bg-light" style={{ fontSize: "20px" }}>
                            {dbRecords.data.curr_avg_bytes}
                          </small>
                        </div>
                        {/* <p class="mb-1">Some placeholder content in a paragraph.</p> */}
                        <small class="text-muted">Average Data Transfer By IP</small>
                      </li>
                      <br />
                      <li class="list-group-item list-group-item-action bg-light border border-dark rounded">
                        <div class="d-flex w-100 justify-content-between">
                          <h5 class="mb-1">High Usage Visitors</h5>
                          <small class="badge bg-light" style={{ fontSize: "20px" }}>
                            {dbRecords.data.percentage_by_ip_aboove_90}
                          </small>
                        </div>
                        {/* <p class="mb-1">Some placeholder content in a paragraph.</p> */}
                        <small class="text-muted">Number of users in the 90th percentile</small>
                      </li>
                    </div>
                    {/* <ol class="list-group list-group-numbered">
                      <li class="list-group-item d-flex justify-content-between align-items-start">
                        <div class="ms-2 me-auto">
                          <div class="fw-bold">Data Transfer Cost</div>
                          {dbRecords.data.cur_prev_percentage_increase}
                        </div>
                        <span class="badge bg-light" style={{ fontSize: "20px" }}>
                          {dbRecords.data.curr_bytes_cost}
                        </span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-start">
                        <div class="ms-2 me-auto">
                          <div class="fw-bold">Average Transfer By Visitor IP</div>
                          Average Data Transfer By IP
                        </div>
                        <span class="badge bg-light" style={{ fontSize: "20px" }}>
                          {dbRecords.data.curr_avg_bytes}
                        </span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-start">
                        <div class="ms-2 me-auto">
                          <div class="fw-bold">High Usage Visitors</div>
                          Number of users in the 90th percentile
                        </div>
                        <span class="badge bg-light" style={{ fontSize: "20px" }}>
                          {dbRecords.data.percentage_by_ip_aboove_90}
                        </span>
                      </li>
                    </ol> */}
                  </>
                )}
                {!loadStatus.loading && loadStatus.error && (
                  <>
                    <div className="row">&nbsp;</div>
                    <div className="row">&nbsp;</div>
                    <div className="alert alert-danger py-1" role="alert">
                      {loadStatus.errorMessage}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </>
    </React.Fragment>
  )
}

export default TotalProjects
