import React, { useState } from "react"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/header/header"
import { useUserOrgs } from "../../../hooks/use-user"
import CounterNoticeCreate from "../../../components/dmca/counternotice/create"
import "../../../styles/metrics.scss"
import { Loading } from "../../../components/loading"
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs"
import DtmContainer from "../../../components/admin/data-transfer-management/container"
import DtmContainerParent from "../../../components/admin/data-transfer-management-parent/container"
import DtmContainerManage from "../../../components/admin/data-transfer-management-manage/container"
import { Col, Nav, NavItem, Row, TabContent, TabPane, NavLink } from "reactstrap"
import _ from "lodash"
import classnames from "classnames"
import { css } from "glamor"
import RepoList from "../../../components/metrics/projects/detail/git/repo-list"
import Contribution from "../../../components/metrics/projects/detail/git/git-contribution"
import MiddleForm from "../middleForm"

export default function CreatePage(props) {
  const {
    loading,
    currentOrg: {
      index,
      role: { isProjectAdmin, isUserAdmin, isSystemAdmin, isSuperAdmin },
    },
  } = useUserOrgs()
  const [tabsName, setTabName] = useState([
    { id: "Systemwide", value: "Systemwide" },
    { id: "Parent Organization", value: "Parent Organization" },
    { id: "Managed Group", value: "Managed Group" },
  ])
  const [activeTab, setActiveTab] = useState("Parent Organization")
  const [modal, setModal] = useState(false)
  const changeTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  const toggle = () => {
    setModal(!modal)
  }
  const navLink = tabsName => {
    return tabsName.map((item, index) => {
      if (item.value == "Systemwide") {
        return (
          isSuperAdmin && <NavItem key={item.id}>
            <NavLink
              className={classnames({ active: activeTab === item.id })}
              onClick={() => {
                changeTab(item.id)
              }}
              key={item.id}
              {...css(styles.navLink)}
            >
              {item?.value}
            </NavLink>
          </NavItem>
        )
      } else {
        return (
          <NavItem key={item.id}>
            <NavLink
              className={classnames({ active: activeTab === item.id })}
              onClick={() => {
                changeTab(item.id)
              }}
              key={item.id}
              {...css(styles.navLink)}
            >
              {item?.value}
            </NavLink>
          </NavItem>
        )
      }
    })
  }
  if (loading) {
    return <Loading />
  }
  return (
    <Layout pageTitle="Data Transfer Management">
      {(isSuperAdmin  || isSystemAdmin) && (
        <>
          <Breadcrumbs breadcrumbs={[{ label: "Data Transfer Management" }]} />
          <Header contentPage={true} className="contentPage">
            <h1 className="display-4 font-weight-bold">Data Transfer Management</h1>
          </Header>
          <Content>
            <Nav tabs>{navLink(tabsName)}
              <div className="float-right" style={{
                position: "absolute",
                right: "32px",
              }}>
                <button type="button" className="btn btn-danger btn-sm" onClick={toggle}>
                  <strong style={{ color: "#fff" }}>Set
                    Threshold Notifications</strong>
                  {/*<NavLink style={{ display: "inline" }} href="/admin/middleForm"></NavLink>*/}
                </button>
              </div>
            </Nav>
            <TabContent activeTab={activeTab} {...css(styles.tabContent)}>
              <TabPane tabId="Systemwide">{activeTab === "Systemwide" && <DtmContainer />}</TabPane>
              <TabPane tabId="Parent Organization">{activeTab === "Parent Organization" &&
              <DtmContainerParent />}</TabPane>

              <TabPane tabId="Managed Group">{activeTab === "Managed Group" && <DtmContainerManage />}</TabPane>
            </TabContent>
            {/*<DtmContainerParent />*/}
            <MiddleForm modal={modal} toggle={toggle}></MiddleForm>
          </Content>
        </>
      )}
      {!(isSuperAdmin || isSystemAdmin) && (
        <Content>
          <div className="alert alert-danger" role="alert">
            <strong>Access Denied</strong>
          </div>
        </Content>
      )}
    </Layout>
  )
}
const styles = {
  noData: {
    marginTop: "45px",
  },
  form: {
    maxHeight: "calc(100vh - 200px)",
    overflowY: "auto",
  },
  tabContent: {
    height: "100%",
    width: "100%",
    marginTop: "25px",
  },
  navLink: {
    cursor: "pointer",
  },
}
