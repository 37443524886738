import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { AccountsContext } from "../context"

import _ from "lodash"
import OutboundBarChart from "../charts/outbound-data-transfer-yearly-chart"

function TotalUsers(props) {
  let contextData = useContext(AccountsContext)

  var [show, setShow] = useState(false)

  useEffect(() => {
    // console.log(contextData)
    if (contextData.submitCounter === 0) return
    setShow(true)
    // LoadTotalUsersReport()
  }, [contextData.submitCounter])

  return (
    <React.Fragment>
      <>
        {show && (
          <>
            <div id="accounts-total-users-data">
              <div className="float-left">
                <h3>Outbound Data Transfer</h3>
              </div>

              <br />
              <br />

              <div className="container">
                <div className="row">
                  <div className="col-sm">
                    <OutboundBarChart />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </React.Fragment>
  )
}

export default TotalUsers
