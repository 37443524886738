import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import echarts from "echarts"
import { css } from "glamor"
import { generateRandomInteger } from "../../../../utils/common"
import { AccountsContext } from "../context"
import * as Sentry from "@sentry/gatsby"
import _ from "lodash"
import {
  fetch_delete,
  fetch_get,
  fetch_patch,
  fetch_post,
  fetch_put,
} from "../../../../utils/fetch"
import Chart from "react-apexcharts"
import { FaRedoAlt } from "react-icons/fa"

export default function EchartsHozBar(props) {
  var [chartElementId, setChartElementId] = useState(null)

  let contextData = useContext(AccountsContext)
  const { getAccessTokenSilently } = useAuth0()
  var [show, setShow] = useState(false)
  var [dbRecords, setdbRecords] = useState({ data: [], scale: "" })
  var [dbRecordss, setdbRecordss] = useState({ data: [], scale: "" })
  var [dbRecordsss, setdbRecordsss] = useState({ data: [], scale: "" })
  var [loadStatus, setLoadStatus] = useState({ loading: false, error: false, errorMessage: null })
  var [chartSettings, setChartSettings] = useState({ options: {}, series: {} })

  const createElementIdForChart = () => {
    var id1 = generateRandomInteger(10000)
    var id2 = generateRandomInteger(10000)
    var id3 = generateRandomInteger(10000)
    var newId = `${id1}${id2}${id3}`
    setChartElementId(newId)
  }
  // console.log(props, 'props')

  const getDataSource_for_graph = () => {
    var res = []

    res.push({
      name: "Git",
      data: [],
    }, {
      name: "Artifactory",
      data: [],
    }, {
      name: "Bundles",
      data: [],
    })

    dbRecords.data.forEach(record => {
      res[0].data.push(record.git_bytes)
    })
    dbRecordss.data.forEach(record => {
      res[1].data.push(record.artifact_bytes)
    })
    dbRecordsss.data.forEach(record => {
      res[2].data.push(record.bundles_bytes)
    })
    console.log(res)
    return res
  }

  const get_graph_label = () => {
    var res = []

    // console.log(dbRecords.length)
    // console.log(dbRecords)
    dbRecords.data.forEach(record => {
      res.push(record.dt)
    })
    // console.log(res)
    return res
  }

  const drawBar = () => {
    const defaultColor = ["green", "orange"]

    var cs = {
      // colors: defaultColor,
      series: getDataSource_for_graph(),
      options: {
        chart: {
          // height: 350,
          type: "area",
          stacked: false,
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        // colors: ["#FF1654", "#247BA0"],
        series: getDataSource_for_graph(),
        stroke: {
          width: [4, 4],
        },
        // plotOptions: {
        //   bar: {
        //     columnWidth: "20%",
        //   },
        // },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
        xaxis: {
          labels: {
            rotate: -60,
          },
          categories: get_graph_label(),
          tickPlacement: "on",
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              // color: "#FF1654",
            },
            labels: {
              style: {
                // colors: "#FF1654",
              },
            },
            title: {
              text: "Usage in TB",
              // style: {
              //   color: "#FF1654",
              // },
            },
          },
          // {
          //   opposite: true,
          //   axisTicks: {
          //     show: true,
          //   },
          //   axisBorder: {
          //     show: true,
          //     color: "#247BA0",
          //   },
          //   labels: {
          //     style: {
          //       colors: "#247BA0",
          //     },
          //   },
          //   title: {
          //     text: "Series B",
          //     style: {
          //       color: "#247BA0",
          //     },
          //   },
          // },
        ],
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            show: true,
          },
          y: {
            formatter: function(val) {
              return val + " " + dbRecords.scale
            },
          },
        },
        legend: {
          horizontalAlign: "left",
          offsetX: 40,
          position: "top",
        },
      },
    }

    setChartSettings(cs)
  }

  const LoadTotalUsersReport = async () => {
    // var orgCode = contextData.selectedParentOrganization.name

    var startDate = contextData.startDate
    var endDate = contextData.endDate
    var postdata = {
      startDate: startDate,
      endDate: endDate,
    }
    setLoadStatus({ loading: true, error: false, errorMessage: null })
    // await sleep(500)
    getAccessTokenSilently()
      .then(accessToken =>
        // fetch_get(`admin/${orgCode}/total/users/report`, accessToken)
        fetch_post(`admin/data-transfer-management/outbound-graph-yearly`, postdata, accessToken)
          .then(response => {
            if (response["git_system_outbound_graph_yearly"].data.length > 0) contextData.setHasRecords(true)
            setdbRecords(response["git_system_outbound_graph_yearly"])
            setdbRecordss(response["artifact_system_outbound_graph_yearly"])
            setdbRecordsss(response["bundles_system_outbound_graph_yearly"])
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
            Sentry.captureException(error)
            console.log(`Error while getting workgroup links list: ${error}`)
          }),
      )
      .catch(error => {
        setLoadStatus({ loading: false, error: true, errorMessage: `${error}` })
        Sentry.captureException(error)
        console.log(`Error while getting acdcess token: ${error}`)
      })
  }

  useEffect(() => {
    // console.log(contextData)
    if (contextData.submitCounter === 0) return

    // console.log(contextData.selectedParentOrganization)
    setShow(true)
    LoadTotalUsersReport()
  }, [contextData.submitCounter])

  useEffect(() => {
    if (chartElementId === null) return
    drawBar()
  }, [chartElementId])

  useEffect(() => {
    // console.log(dbRecords)
    createElementIdForChart()
  }, [dbRecords,dbRecordss,dbRecordsss])

  // useEffect(() => {
  //   LoadTotalUsersReport()
  // }, [graphType])

  return (
    <div id="accounts-total-users-graph-data">
      <div className="float-left">{/* <h5>Accounts</h5> */}</div>

      {!contextData.pdfExportStarted && (
        <>
          {/* <div className="float-right"> */}
          {/* <select
              className="form-control-sm"
              value={graphType}
              aria-label="Default select example"
              name="claimContentOnCodelinaroOption"
              onChange={e => setGraphType(e.target.value)}
            >
              {graphTypeOptions.map((p, index) => (
                <option value={p.code}>{p.name}</option>
              ))}
            </select>
            &nbsp; */}
          {/* <button
              type="button"
              className="btn btn-outline-dark btn-sm"
              onClick={() => LoadTotalUsersReport()}
            >
              <FaRedoAlt />
            </button> */}
          {/* &nbsp;
                    <button
                      type="button"
                      className="btn btn-warning btn-sm"
                      onClick={() =>
                        window["generatePDF"]("accounts-total-users-data", "AccountBilling-users")
                      }
                    >
                      <strong>pdf</strong>
                    </button> */}
          {/* &nbsp;
            <button
              type="button"
              className="btn btn-warning btn-sm"
              onClick={() =>
                window["exportTableToExcel"](
                  "accounts-excel-users-data",
                  "",
                  "AccountBilling-users-excel"
                )
              }
            >
              <strong>excel</strong>
            </button> */}
          {/* </div> */}
        </>
      )}
      {loadStatus.loading && !loadStatus.error && (
        <>
          <div className="spinner-grow spinner-grow-sm" role="status"></div>
          Loading...
        </>
      )}
      {/* {!loadStatus.loading && !loadStatus.error && (
        <> */}
      {/* <br />
      <br /> */}
      {/* <div className="row" {...css(styles.borderStyle)} id={chartElementId}></div> */}
      {dbRecords.data.length > 0 && (
        <Chart
          options={chartSettings.options}
          series={chartSettings.series}
          type="area"
          width="100%"
          height="400px"
        />
      )}
      {/* </>
      )} */}
      {!loadStatus.loading && loadStatus.error && (
        <>
          <div className="alert alert-danger py-1" role="alert">
            {loadStatus.errorMessage}
          </div>
        </>
      )}
    </div>
  )
}

const styles = {
  borderStyle: {
    width: "100%",
    height: "300px",
  },
}
