import React, { createContext, useEffect, useState } from "react"
import moment from "moment"
export const AccountsContext = createContext()

export const AccountsContextContextProvider = ({ children }) => {
  var [selectedParentOrganization, setSelectedParentOrganization] = React.useState(null)
  var [selectedOrganization, setSelectedOrganization] = React.useState(null)
  var [submitCounter, setSubmitCounter] = React.useState(0)
  const currentUtcDate = moment.utc()
  const twoWeeksAgoUtcDate = moment.utc().subtract(2, "weeks")
  // console.log(currentUtcDate, twoWeeksAgoUtcDate)
  var [startDate, setStartDate] = React.useState(new Date(twoWeeksAgoUtcDate))
  var [endDate, setEndDate] = React.useState(new Date(currentUtcDate.format("YYYY-MM-DD")))
  var [datesSelected, setDatesSelected] = React.useState(true)
  var [pdfExportStarted, setPdfExportStarted] = React.useState(false)
  var [hasRecords, setHasRecords] = React.useState(false)

  return (
    <AccountsContext.Provider
      value={{
        submitCounter,
        setSubmitCounter,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        datesSelected,
        setDatesSelected,
        pdfExportStarted,
        setPdfExportStarted,
        hasRecords,
        setHasRecords,
      }}
    >
      {children}
    </AccountsContext.Provider>
  )
}
