import React, { useEffect, useContext, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { AccountsContext } from "./context"
import { fetch_delete, fetch_get, fetch_patch, fetch_post, fetch_put } from "../../../utils/fetch"
// import { sleep } from "../../../utils/common"
import * as Sentry from "@sentry/gatsby"
import _ from "lodash"
import DatePicker from "react-datepicker"
import { NavLink } from "reactstrap"
import MiddleForm from "../../../pages/admin/middleForm"

function ProjectLinksList(props) {
  let contextData = useContext(AccountsContext)

  const handle_submit = () => {
    contextData.setSubmitCounter(n => n + 1)
  }
  const [modal, setModal] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      handle_submit()
    }, 1000)
  }, [])
  const toggle = () => {
    setModal(!modal)
  }

  return (
    <React.Fragment>
      <>
        <div className="float-right">
          <button type="button" className="btn btn-success btn-sm" style={{marginRight:5}} onClick={() => handle_submit()}>
            <strong>refresh</strong>
          </button>
          <button type="button" className="btn btn-danger btn-sm" onClick={toggle}>
            <strong style={{ color: "#fff" }}>Set
              Threshold Notifications</strong>
            {/*<NavLink style={{ display: "inline" }} href="/admin/middleForm"></NavLink>*/}
          </button>
        </div>
        <br />
        <MiddleForm modal={modal} toggle={toggle}></MiddleForm>
      </>
    </React.Fragment>
  )
}

export default ProjectLinksList
