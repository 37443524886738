import React, { Component, useContext } from "react"
import { AccountsContextContextProvider } from "./context"
import AccountsIndex from "./index"
import { useUserOrgs } from "../../../hooks/use-user"

function AccountsContainer(props) {
  const {
    currentOrg: {
      role: { isProjectAdmin, isUserAdmin, isSystemAdmin, isSuperAdmin },
    },
  } = useUserOrgs()
  return (
    <React.Fragment>
        <AccountsContextContextProvider>
          <AccountsIndex />
        </AccountsContextContextProvider>
      {/*{!isSuperAdmin && (*/}
      {/*  <div className="alert alert-danger" role="alert">*/}
      {/*    <strong>Access Denied</strong>*/}
      {/*  </div>*/}
      {/*)}*/}
    </React.Fragment>
  )
}

export default AccountsContainer
